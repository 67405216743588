const rolesList = {
  ADMIN:                           'admin',
  COMPANY_USER:                    'company-user',
  CONSUMER:                        'consumer',
  OS_ADMIN:                        'os-admin',
  OS_SENIOR_ADMIN:                 'os-senior-admin',
  OS_INVESTIGATIVE_OFFICER:        'os-investigative-officer',
  OS_SENIOR_INVESTIGATIVE_OFFICER: 'os-senior-investigative-officer',
  OS_DATA_OFFICER:                 'os-data-officer',
  COACH:                           'coach',
  LEADER:                          'leader',
  DRE_ONE:                         'dre-one',
  DRE_TWO:                         'dre-two',
  DRE_THREE:                       'dre-three'
};

const consumerCompany = [rolesList.CONSUMER, rolesList.COMPANY_USER];

const osDREs = [
  rolesList.DRE_ONE,
  rolesList.DRE_TWO,
  rolesList.DRE_THREE
];

const osSupervised = [
  rolesList.COACH,
  ...osDREs
]

const osSingleOwners = [
  rolesList.LEADER,
  ...osSupervised
];

const osRoles = [
  rolesList.OS_ADMIN,
  rolesList.OS_SENIOR_ADMIN,
  rolesList.OS_INVESTIGATIVE_OFFICER,
  rolesList.OS_SENIOR_INVESTIGATIVE_OFFICER,
  rolesList.OS_DATA_OFFICER,
  ...osSingleOwners];

const isSysAdmin = role => role === rolesList.ADMIN;

const isCompany = role => role === rolesList.COMPANY_USER;

const isConsumer = role => role === rolesList.CONSUMER;

const isConsumerCompany = role => consumerCompany.includes(role);

const isOsUser = role => osRoles.includes(role);

const isSingleOwner = role => osSingleOwners.indexOf(role) !== -1;

const isSupervised = role => osSupervised.indexOf(role) !== -1;

const isDRE = role => osDREs.indexOf(role) !== -1;

export {
  rolesList as default,
  rolesList,
  osRoles,
  isSysAdmin,
  isCompany,
  isConsumer,
  isConsumerCompany,
  isOsUser,
  isSingleOwner,
  isSupervised,
  isDRE
};
