import React from "react";
import query from "fetchers/notificationsForSideNav/query"
import markAsRead from "fetchers/notifications/markAsRead";
import { isConsumerCompany } from 'lib/builders/rolesList';

const tabName = "notifications";

export default function wrapper(Component) {


  return class extends React.Component {
    constructor(props) {
      super(props);

      if (
        props.match?.path !== "/signout"
        && isConsumerCompany(props.currentUser?.role)
      ) {
        this.fetchNotifications();
      }
    }

    openTab = () => {
      this.fetchNotifications();
      this.props.setTab(tabName);
    };

    onClick = notification => {
      if (notification && notification.isUnread) {
        this.removeNotification(notification);
        this.props.saveNotificationsCount(this.props.notificationsCount - 1);
        markAsRead({ guids: [notification.id] });
      }

      this.props.closeTab && this.props.closeTab();
    };

    removeNotification = notification =>
      this.props.saveNotifications(
        this.props.notifications.filter(
          (filterItem, i) => String(filterItem.id) !== String(notification.id)
        )
      );

    fetchNotifications = async () => {
      const {notifications, count} = await query();
      this.props.saveNotifications(notifications);
      this.props.saveNotificationsCount(count);
    }

    render = () => (
      <Component
        {...this.props}
        tabName={tabName}
        openTab={this.openTab}
        onClick={this.onClick}
        isActive={this.props.activeTab === tabName}
      />
    );
  };
}
